import React, { useState, useCallback, useEffect } from 'react';
import { DragTool, Droppable, DragSimpleTool } from 'components';
import { QuestionnairePreview } from 'containers';
import { Link, useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { InputSubject, OptionSubject, Box, Modal } from '@oneform/ui-components';
import { Drop_ITEM, EDITOR_TYPE } from 'constants/index';
import update from 'immutability-helper';
import { createQuestionnaire, updateQuestionnaire, getQuestionnaireById } from 'api/questionnaire';
import { deleteQuestionnaireSubject } from 'api/subject';
import { Input, Button, Space } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import {
  UiEditor,
  UiEditorContent,
  UiToolsContent,
  UiEditorTool
} from './Editor.style';

const tools = [
  {
    id: 0,
    type: EDITOR_TYPE.TEXT,
    label: '單行文字'
  },
  {
    id: 1,
    type: EDITOR_TYPE.SINGLE_CHOICE,
    label: '下拉選單'
  }
];

const EDITOR_PAGE_TYPE = {
  EDIT: 'edit',
  CREATE: 'create'
};

export const Editor = ({ type }) => {
  const history = useHistory();
  const [isVisible, setIsVisible] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [editInitSubjects, setEditInitSubjects] = useState([]);
  const [deleteSubjects, setDeleteSubjects] = useState([]);
  const [formSetting, setFormSetting] = useState({
    title: '',
    description: '',
    appreciationTitle: '',
    appreciationContent: '',
    copyrightTitle: '',
    copyrightContent: ''
  });
  const { id } = useParams();

  const fetchQuestionnaireById = async (id) => {
    const res = await getQuestionnaireById(id);
    const {
      title, subjects, description,
      appreciation: { content: appreciationContent, title: appreciationTitle }
    } = res.data;
    setFormSetting({
      title,
      subjects,
      description,
      appreciationTitle,
      appreciationContent,
      copyrightTitle: res.data.copyright?.title || '',
      copyrightContent: res.data.copyright?.content || ''
    });
    setSubjects([...subjects]);
    setEditInitSubjects([...subjects]);
  };

  const toggle = () => setIsVisible(!isVisible);

  const onSubjectSave = (res, index) => {
    const newSubject = subjects.map((subject, subjectIndex) => {
      if (subjectIndex === index) {
        return {
          ...subject,
          ...res
        };
      } else {
        return subject;
      }
    });
    setSubjects(newSubject);
  };

  const onDeleteHandler = async(index, subject) => {
    const { subjectId } = subject;
    const nextSubjects = subjects.filter((item, subjectIndex) => index !== subjectIndex);
    setDeleteSubjects([...deleteSubjects, ...[subjectId]]);
    setSubjects(nextSubjects);
  };


  const switchSubject = (subject, index) => {
    switch (subject.type) {
      case EDITOR_TYPE.TEXT:
        return (
          <InputSubject data={subject} index={index}
            onSave={(res) => onSubjectSave(res, index)}
            onCancel={() => onCancelHandler(index)}
            onDelete={() => onDeleteHandler(index, subject)} />
        );
      case EDITOR_TYPE.SINGLE_CHOICE:
        return (
          <OptionSubject data={subject} index={index}
            onSave={(res) => onSubjectSave(res, index)}
            onCancel={() => onCancelHandler(index)}
            onDelete={() => onDeleteHandler(index, subject)} />
        );
      default:
        return null;
    }
  };

  const dropHandle = (dropItem) => {
    const { type } = dropItem;
    let subject = {
      id: uuidv4(),
      type,
      label: '',
      description: '',
      placeholder: '',
      rules: {
        required: false,
      },
      isHidden: false
    };
    switch (type) {
      case EDITOR_TYPE.SINGLE_CHOICE:
        subject = {
          ...subject,
          options: []
        };
        break;
      default:
        break;
    }
    setSubjects([...subjects, subject]);
  };

  const moveHandle = useCallback((dragIndex, hoverIndex) => {
    const dragCard = subjects[dragIndex];
    setSubjects(update(subjects, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragCard],
      ],
    }));
  }, [subjects]);

  const onInputChange = (e, key) => {
    const value = e.target.value;
    setFormSetting({
      ...formSetting,
      [key]: value
    });
  };

  const getQuestionnaire = () => {
    const {
      title,
      description,
      appreciationTitle,
      appreciationContent,
      copyrightTitle,
      copyrightContent
    } = formSetting;

    const payload = {
      title,
      description,
      subjects,
      appreciation: {
        title: appreciationTitle,
        content: appreciationContent
      },
      copyright: {
        title: copyrightTitle,
        content: copyrightContent
      }
    };

    return payload;
  };

  const submitHandle = async () => {
    const payload = getQuestionnaire();
    const nextDeleteSubjects = deleteSubjects.filter(subjectId => {
      return editInitSubjects.filter(initItem => initItem.subjectId === subjectId).length > 0;
    });

    if (type === EDITOR_PAGE_TYPE.CREATE) {
      await createQuestionnaire(payload);
    } else {
      nextDeleteSubjects.map(async subjectId => {
        await deleteQuestionnaireSubject(id, subjectId);
      });
      await updateQuestionnaire(id, payload);
    }
    alert('成功！');
    history.push('/');
  };

  const navigateToPreview = () => {
    const payload = getQuestionnaire();
    window.localStorage.setItem('preview', JSON.stringify(payload));
    setIsVisible(true);
  };

  const onCancelHandler = (index) => {
    const nextSubjects = subjects.filter((item, subjectIndex) => index !== subjectIndex);
    setSubjects(nextSubjects);
  };

  useEffect(() => {
    if (!id) return;
    fetchQuestionnaireById(id);
  }, []);

  return (
    <UiEditor>
      <UiEditorContent>
        <Droppable type={Drop_ITEM.TOOL} dropHandle={dropHandle} >
          {
            subjects.map((subject, index) => {
              return (
                <DragSimpleTool
                  moveHandle
                  index={index}
                  id={subject.id || subject.subjectId}
                  key={subject.id || subject.subjectId}
                  type='CARD'
                  data={subject}
                  moveHandle={moveHandle}
                  className="editorContent_tool"
                >
                  {switchSubject(subject, index)}
                </DragSimpleTool>
              );
            })
          }
        </Droppable>
      </UiEditorContent>
      <UiToolsContent>
        <Droppable type={Drop_ITEM.TOOL} className="toolsContent_draggable">
          {
            tools.map(tool => {
              return (
                <DragTool key={tool.id} type={Drop_ITEM.TOOL} className="toolsContent_tool" data={tool}>
                  <UiEditorTool>
                    {tool.label}
                  </UiEditorTool>
                </DragTool>
              );
            })
          }
        </Droppable>
        <div className="finalSettingContent">
          <div className="finalSetting">
            <Box my={2}>
              <span>標題*</span>
              <Input value={formSetting.title} onChange={(e) => onInputChange(e, 'title')}/>
            </Box>
            <Box my={2}>
              <span>表單描述</span>
              <Input value={formSetting.description} onChange={(e) => onInputChange(e, 'description')}/>
            </Box>
            <Box my={2}>
              <span>問卷結束語標題</span>
              <Input value={formSetting.appreciationTitle} onChange={(e) => onInputChange(e, 'appreciationTitle')}/>
            </Box>
            <Box my={2}>
              <span>問卷結束語內容</span>
              <Input value={formSetting.appreciationContent} onChange={(e) => onInputChange(e, 'appreciationContent')}/>
            </Box>
            <Box my={2}>
              <span>聲明文字</span>
              <Input
                value={formSetting.copyrightTitle}
                onChange={(e) => onInputChange(e, 'copyrightTitle')}
              />
            </Box>
            <Box my={2}>
              <span>聲明內容</span>
              <Input.TextArea
                value={formSetting.copyrightContent}
                placeholder="聲明內容"
                autoSize={{ maxRows: 4 }}
                rows={4}
                showCount={true}
                onChange={(e) => onInputChange(e, 'copyrightContent')}
              />
            </Box>
          </div>
          <div className="finalSettingAction">
            <Box justifyContent="flex-end" display="flex">
              <Space>
                <Button onClick={navigateToPreview}>預覽</Button>
                <Button type="primary" onClick={submitHandle}>送出表單</Button>
              </Space>
            </Box>
          </div>
        </div>
      </UiToolsContent>
      <Modal
        title="預覽"
        visible={isVisible}
        footer={[<Button key="back" onClick={toggle}>關閉</Button>]}
        onCancel={toggle}
      >
        <QuestionnairePreview data={getQuestionnaire()}/>
      </Modal>
    </UiEditor>
  );
};
import React, { createContext, useContext, memo } from 'react';
import { useMachine } from '@xstate/react';
import { useHistory } from 'react-router-dom';
import authenticationMachine from 'machines/authMachine';
import PropTypes from 'prop-types';

const { machine } = authenticationMachine;

const StateContext = createContext();
const DispatchContext = createContext(() => {});

const AuthProvider = memo(({ children }) => {
  const history = useHistory();
  const [state, send] = useMachine(machine, {
    context: {
      navigatorAuth: () => history.push('/login')
    }
  });

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={send}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  );
}, []);

const useAuthState = () => useContext(StateContext);

const useAuthDispatch = () => useContext(DispatchContext);


export default AuthProvider;
export { useAuthState, useAuthDispatch };

AuthProvider.propTypes = {
  children: PropTypes.node,
};

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { DatePicker, Button, Space } from 'antd';
import { getUnixTime } from 'date-fns';
import { UiRecordList } from './RecordList.style';
import { exportFile } from 'utils/common';

const { RangePicker } = DatePicker;
/**
 * 範本列表
 */

export const RecordList = () => {
  const [rangeTime, setRangeTime] = useState({
    startTime: '',
    endTime: ''
  });
  const { id: questionnaireId } = useParams();

  const datePickerChangeHandler = (date, dateString) => {
    const startTime = getUnixTime(new Date(dateString[0])) * 1000;
    const endTime = getUnixTime(new Date(dateString[1])) * 1000;
    setRangeTime({
      startTime,
      endTime
    });
  };

  const onExportHandler = async () => {
    const { startTime, endTime } = rangeTime;

    if (startTime === '' || endTime === '' || !questionnaireId) return;

    const url = `${process.env.REACT_APP_DOMAIN}/admin/questionnaire/${questionnaireId}/record/export`;
    exportFile(url, 'get', {
      startAt: startTime,
      endAt: endTime
    });
  };

  return (
    <UiRecordList>
      <Space>
        <RangePicker onChange={datePickerChangeHandler} />
        <Button type='primary' onClick={onExportHandler}>匯出</Button>
      </Space>
    </UiRecordList>);
};
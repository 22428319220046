import styled from 'styled-components';
import { space, color, layout, flexbox, position } from 'styled-system';

export const Box = styled.div`
	${space}
	${color}
	${layout}
	${flexbox}
	${position}
`;

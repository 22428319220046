import styled from 'styled-components';
import { get } from '../../utils/themeGet';

export const UiSettingBlock = styled.div`
  width: 100%;
  >* {
    margin: 4px 8px;
  }
`;

export const UiOptionBlock = styled.ol`
  padding-left: 24px;

  &> li + li{
    margin-top: 12px;
  }
`;

export const UiOptionItem = styled.div`
  display: flex;
  align-items: center;

  .optionNumber {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 30px;
  }
  .optionActions {
    display: flex;
  }
`;

export const UiOptionAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  color: ${get('colors.scale.gray.$gray3')};

  &:hover {
    color: ${get('colors.scale.gray.$gray8')};
  }
`;

import { SideLayout } from '@oneform/ui-components';
import { Menu, UserAvatar } from 'components';
import RenderRoutes from 'routes/RenderRoutes';
import { UiHeader } from './MainLayout.style';
import { useAuthState, useAuthDispatch } from 'providers/AuthProvider';
import { AuthenticationMachineEvent } from 'machines/authMachine';

export const MainLayout = ({ routes }) => {
  const authState = useAuthState();
  const authDispatch = useAuthDispatch();
  const { name } = authState.context.userDetails || {};
  const logoutHandler = () => authDispatch(AuthenticationMachineEvent.LOG_OUT);

  return (
    <SideLayout
      siderContent={<Menu /> }
      headerContent={
        <UiHeader>
          <UserAvatar name={name} onLogout={logoutHandler} />
        </UiHeader>
      }>
      <RenderRoutes routes={routes} />
    </SideLayout>
  );
};
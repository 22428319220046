import styled from 'styled-components';

export const UiUserAvatar = styled.div`
	display: block;
`;

export const UiUserAction = styled.div`
	display: inline-block;
	color: #fff;
	cursor: pointer;
`;
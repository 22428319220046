import { Api } from 'api';

/**
 * 取得範本列表
 */
export const getQuestionnaireList = async (payload) => {
  const response = await Api.get('/questionnaire', payload);
  return response;
};

/**
 * 取得範本列表
 */
export const getQuestionnaireById = async (Id) => {
  const response = await Api.get(`/questionnaire/${Id}`);
  return response;
};

/**
 * 建立範本
 */
export const createQuestionnaire = async (payload) => {
  const response = await Api.post('/admin/questionnaire', payload);
  return response;
};

/**
 * 更新範本
 */
export const updateQuestionnaire = async (Id, payload) => {
  const response = await Api.put(`/admin/questionnaire/${Id}`, payload);
  return response;
};
import styled from 'styled-components';

export const UiInput = styled.div`
	display: block;
	
	> .label {
		color:#242c3f;
		font-size: 14px;
	}

	> .ant-input {
		padding: 12px;
		color:#242c3f;
		border-radius:8px;
		
		&:focus{
			border: 1px solid #121232;
			box-shadow: none;
		}
	}
`;
import React from 'react';
import PropTypes from 'prop-types';
import FormItem from '../FormItem';
import { UiFormInput } from './FormInput.style';


/**
 * 表單 input
 */

export const FormInput = ({ subjectInfo, onChange = () => {} }) => {
  return (
    <FormItem subjectInfo={subjectInfo}>
      <UiFormInput onChange={onChange}
        value={subjectInfo.value}
        placeholder={`${subjectInfo?.placeholder || '請輸入'}${(subjectInfo?.rules?.required === true) ? '*' : ''}`}>
      </UiFormInput>
    </FormItem>
  );
};

FormInput.propTypes = {
  subjectInfo: PropTypes.object,
  onChange: PropTypes.func
};



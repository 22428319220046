import { removeUndefinedFromObject } from 'utils/common';

const toSBC = (str) => {
  //轉半形
  let result = '';
  const len = str.length;
  for (let i = 0; i < len; i++) {
    let cCode = str.charCodeAt(i);
    //全形與半形相差（除空格外）：65248（十進位制）
    cCode = cCode >= 0xff01 && cCode <= 0xff5e ? cCode - 65248 : cCode;
    //處理空格
    cCode = cCode === 0x03000 ? 0x0020 : cCode;
    result += String.fromCharCode(cCode);
  }
  return result;
};

const isEmpty = (value) => (value === undefined || value === null || value === '' ? false : true);

export const required = (value) => isEmpty(value);

export const regex = (value, re) => RegExp(re).test(value);

export const validateLength = (max, min, count) => ((count >= min) && (count <= max)) ? false : true;

export const checkDuplicateName = (name, checkArray) => {
  for (const item of checkArray) {
    const newItem = toSBC(item).toLowerCase();
    if (newItem === toSBC(name).toLowerCase()) return false;
  }
  return true;
};

export const validateRequired = value => {
  let errorMsg = '';
  let isPass = required(value);
  if (!isPass) {
    errorMsg = '欄位必須填寫';
  }
  return [isPass, errorMsg];
};

export const validateMaxLength = maxLength => target => {
  let errorMsg = '';
  let isPass = target.length <= maxLength;
  if (!isPass) errorMsg = `字數請小於${maxLength}`;
  return [isPass, errorMsg];
};

export const validateMinLength = minLength => target => {
  let errorMsg = '';
  let isPass = target.length >= minLength;
  if (!isPass) errorMsg = `字數請大於${minLength}`;
  return [isPass, errorMsg];
};

export const createValidator = rules => {
  const validations = Object.entries(removeUndefinedFromObject({
    required: rules.required,
    maxLength: rules.maxLength,
    minLength: rules.minLength
  }))
    .filter(([_, value]) => value)
    .map(([key, value]) => {
      switch (key) {
        case 'required':
          return validateRequired;
        case 'maxLength':
          return validateMaxLength(value);
        case 'minLength':
          return validateMinLength(value);
      }
    });
  const validate = value => {
    let errorMsg = '';
    for (let i = 0; i < validations.length; i++) {
      const [isPass, msg] = validations[i](value);
      if (!isPass) {
        errorMsg = msg;
        break;
      }
    }
    return [!errorMsg, errorMsg];
  };
  return validate;
};

export const createLoginValidator = (formData, rules) => {
  let isPass = false;
  const errorGroup = {};
  Object.entries(formData).forEach(([key, value]) => {
    let isFirstValidator = true;
    if (!rules[key]) return;
    rules[key].forEach((rule) => {
      if (!rule.validate(value)) {
        if (!isFirstValidator) return;
        errorGroup[key] = {
          value: '',
          message: rule.message,
        };
        isFirstValidator = false;
        isPass = true;
      }
    });
  });
  return [isPass, errorGroup];
};

import { assign, createMachine } from 'xstate';
import { logoutNaniOneClass, checkNaniLinkLogin, getProfile as getProfileApi } from 'services/oneClub';

export const AuthenticationMachineState = {
  checkingIfLoggedIn: 'checkingIfLoggedIn',
  checkIfLoggedIn: 'checkIfLoggedIn',
  loggedIn: 'loggedIn',
  loggedOut: 'loggedOut',
};

export const AuthenticationMachineEvent = {
  REPORT_IS_LOGGED_IN: 'REPORT_IS_LOGGED_IN',
  REPORT_IS_LOGGED_OUT: 'REPORT_IS_LOGGED_OUT',
  LOG_IN: 'LOG_IN',
  LOG_OUT: 'LOG_OUT',
};

const authenticationMachine = createMachine(
  {
    id: 'authentication',
    initial: AuthenticationMachineState.checkingIfLoggedIn,
    states: {
      [AuthenticationMachineState.checkingIfLoggedIn]: {
        invoke: {
          src: AuthenticationMachineState.checkIfLoggedIn,
          onError: {
            target: AuthenticationMachineState.loggedOut,
          },
        },
        on: {
          [AuthenticationMachineEvent.REPORT_IS_LOGGED_IN]: {
            target: AuthenticationMachineState.loggedIn,
            actions: 'assignUserDetailsToContext',
          },
          [AuthenticationMachineEvent.REPORT_IS_LOGGED_OUT]: AuthenticationMachineState.loggedOut,
        },
      },
      [AuthenticationMachineState.loggedIn]: {
        on: {
          [AuthenticationMachineEvent.LOG_OUT]: {
            target: AuthenticationMachineState.loggedOut,
          },
        },
      },
      [AuthenticationMachineState.loggedOut]: {
        entry: ['navigateToAuthPage', 'clearUserDetailsFromContext'],
        on: {
          [AuthenticationMachineEvent.LOG_IN]: {
            target: AuthenticationMachineState.loggedIn,
            actions: 'assignUserDetailsToContext',
          },
        },
      },
    },
  },
  {
    services: {
      checkIfLoggedIn: () => async (
        send,
      ) => {
        const cookieGroup = checkNaniLinkLogin();
        if (cookieGroup) {
          const jwt = JSON.parse(cookieGroup).jwt;
          const res = await getProfileApi({ jwt });
          if (res.identity === '編輯') {
            send({
              type: 'REPORT_IS_LOGGED_IN',
              userDetails: {
                ...res
              },
            });
          } else {
            send({
              type: 'REPORT_IS_LOGGED_OUT',
            });
          }
        } else {
          send({
            type: 'REPORT_IS_LOGGED_OUT',
          });
        }
      },
    },
    actions: {
      navigateToAuthPage: (context) => {
        const { navigatorAuth } = context;
        logoutNaniOneClass();
        navigatorAuth();
      },
      assignUserDetailsToContext: assign((context, event) => {
        if (event.type !== AuthenticationMachineEvent.REPORT_IS_LOGGED_IN && event.type !== AuthenticationMachineEvent.LOG_IN) {
          return {};
        }
        return {
          userDetails: event.userDetails,
        };
      }),
      clearUserDetailsFromContext: assign({
        userDetails: undefined,
      }),
    },
  },
);

export default authenticationMachine;

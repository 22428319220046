import React, { useState } from 'react';
import { UiSideLayout, UiSiteLayout, UiHeader, UiContent, UiFooter, UiSider } from './SideLayout.style';

export const SideLayout = ({ siderContent, headerContent, children }) => {
  const [collapsed, setCollapsed] = useState(false);

  const onCollapse = () => {
    setCollapsed(prv => !prv);
  };

  return (
    <UiSideLayout>
      <UiSider collapsible collapsed={collapsed} onCollapse={onCollapse}>
        {siderContent}
      </UiSider>
      <UiSiteLayout>
        <UiHeader style={{
          padding: '0 12px'
        }}>
          {headerContent}
        </ UiHeader>
        <UiContent>
          {children}
        </UiContent>
        <UiFooter style={{
          height: '50px',
          padding: '12px 50px'
        }}
        >OneForm ©2021 copyright</UiFooter>
      </UiSiteLayout>
    </UiSideLayout>
  );
};


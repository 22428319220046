import React from 'react';
import PropTypes from 'prop-types';
import { useDrag } from 'react-dnd';
import { UiDragTool } from './DragTool.style';

export const DragTool = ({ type, data, children, className }) => {

  const [{ isDragging }, drag] = useDrag({
    type,
    item: {
      ...data
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <UiDragTool ref={drag} style={{ opacity: isDragging ? '0.5' : '1', }} className={className}>
      {children}
    </UiDragTool>
  );
};

DragTool.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.any,
  children: PropTypes.any,
  className: PropTypes.string
};
import React, { useState } from 'react';
import { useSetState } from '../../utils/hooks/useSetState';
import { Input, InputNumber, Checkbox } from 'antd';
import Box from '../Box';
import PropTypes from 'prop-types';
import SubjectBox from '../SubjectBox';
import { UiSettingBlock } from './InputSubject.style';

export const InputSubject = ({ onSave, onDelete, index, data }) => {
  const [{
    isRequired,
    isDesc,
    isMaxLength,
    isHidden,
    isEdit,
    label,
    description,
    placeholder,
    maxLength,
    minLength,
  }, setState] = useSetState({
    isRequired: data.rules.required || false,
    isDesc: data.description !== '' || false,
    isMaxLength: Object.prototype.hasOwnProperty.call(data.rules, 'maxLength') || false,
    isHidden: data.isHidden || false,
    isEdit: true,
    label: data.label || '',
    description: data.description || '',
    placeholder: data.placeholder || '',
    maxLength: data.rules.maxLength || 100,
    minLength: data.rules.minLength || 1
  });
  const [prevData, setPrevData] = useState({});

  const options = [
    { label: '是否必填', name: 'isRequired', checked: isRequired },
    { label: '備註', name: 'isDesc', checked: isDesc },
    { label: '字數長度', name: 'isMaxLength', checked: isMaxLength },
    { label: '隱藏欄位', name: 'isHidden', checked: isHidden }
  ];

  const formatParams = () => {
    const newState = {
      isRequired,
      isDesc,
      isMaxLength,
      isHidden,
      label,
      description,
      placeholder,
      maxLength,
      minLength,
    };

    const result = {
      label: newState.label,
      description: newState.isDesc ? newState.description : '',
      placeholder: newState.placeholder,
      rules: {
        maxLength: newState.isMaxLength ? newState.maxLength : 100,
        minLength: newState.isMaxLength ? newState.minLength : 1,
        required: newState.isRequired,
      },
      isHidden: newState.isHidden,
    };
    onSave(result);
  };

  const onChangeHandler = event => {
    const name = event.target.name;
    const value = event.target.checked;
    setState({
      [name]: value
    });
  };

  const onInputChangeHandler = (event, key) => {
    const value = event.target?.value;
    setState({
      [key]: value
    });
  };

  const onInputNumberChangeHandler = (value, key) => {
    setState({
      [key]: value
    });
  };

  const onEdit = () => {
    setPrevData({
      isRequired,
      isDesc,
      isMaxLength,
      isHidden,
      label,
      description,
      placeholder,
      maxLength,
      minLength
    });
    setState({
      isEdit: !isEdit
    });
  };

  const onSaveHandler = () => {
    setState({
      isEdit: false
    });
    formatParams();
  };

  const onCancel = () => {
    setState({
      ...prevData,
      isEdit: false,
    });
  };

  const onInputNumberKeyDownHandler = (e) => {
    if ((e.keyCode < 48 || e.keyCode > 57) && e.keyCode != 8) {
      e.preventDefault();
    }
  };

  return (
    <SubjectBox
      isRequired={isRequired}
      index={index}
      onDelete={onDelete}
      onCancel={onCancel}
      onEdit={onEdit}
      onSave={onSaveHandler}
      isEdit={isEdit}>
      <Box my={3}>
        <Input placeholder='標題' onChange={(e) => onInputChangeHandler(e, 'label')} value={label}/>
      </Box>
      {isEdit && <Box>
        {isDesc && <Box my={3}>
          <Input placeholder='備註說明' onChange={(e) => onInputChangeHandler(e, 'description')} value={description}/>
        </Box>}
        <UiSettingBlock>
          {options.map(option => <Checkbox key={option.name} name={option.name} checked={option.checked} onChange={onChangeHandler}>{option.label}</Checkbox>)}
        </UiSettingBlock>
        {isMaxLength && <Box my={3}>
          字數長度介於
          <Box display='inline-block' mx={2}>
            <InputNumber onKeyDown={onInputNumberKeyDownHandler} min={0} max={100} onChange={(value) => onInputNumberChangeHandler(value, 'minLength')} value={minLength}/>
          </Box>
            與
          <Box display='inline-block' mx={2}>
            <InputNumber min={0} max={100} onKeyDown={onInputNumberKeyDownHandler} onChange={(value) => onInputNumberChangeHandler(value, 'maxLength')} value={maxLength}/>
          </Box>
            之間
        </Box>}
        <Box my={3}>
          <Input placeholder='請輸入文字輸入框預設文字' onChange={(e) => onInputChangeHandler(e, 'placeholder')} value={placeholder}/>
        </Box>
      </Box>}
      {<Box my={3}>
        <Input />
      </Box>}
    </SubjectBox>
  );
};

InputSubject.propTypes = {
  index: PropTypes.number
};
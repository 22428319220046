import React from 'react';
import PropTypes from 'prop-types';
import { UiFilletButton } from './FilletButton.style';


/**
 * 圓角按鈕
 */

export const FilletButton = ({ children, className, onClick = () => {} }) => {
  return (
    <UiFilletButton className={className} onClick={onClick}>
      {children}
    </UiFilletButton>
  );
};

FilletButton.propTypes = {
  children: PropTypes.any
};



import styled from 'styled-components';
import Box from '../Box';

export const UiSelectIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  pointer-events: auto;
  cursor: pointer;
`;

export const UiFormSelect = styled(Box)`
  > .ant-select{
    width: 100%;
  }
  .ant-select-selector {
    width: 100%;
    height: 48px!important;
    border: 1px solid #D5D7DE!important;
    border-radius: 8px!important;
    padding: 12px!important;
  }
  .ant-select-selection-placeholder{
    line-height: 24px!important;
  }
  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border: 1px solid #121232!important;
    box-shadow: none !important;
  }
  .ant-select-selection-item{
    line-height: 24px!important;
  }
  .ant-select-item {
    padding: 12px;
    font-size: 14px;
    color: #242C3F;
  } 

  .ant-select-item-option-active:not(.ant-select-item-option-disabled){
    background: #F2F4F8;
  }
`;
import { notification } from 'antd';

const notificationStyle = {
  success: {
    backgroundColor: '#f6ffed',
    border: '1px solid #b7eb8f',
  },
  error: {
    backgroundColor: '#fff1f0',
    border: '1px solid #ffa39e',
  },
};

const openNotificationWithIcon = (type, desc) => {
  notification[type]({
    message: desc,
    style: notificationStyle[type],
  });
};

export default openNotificationWithIcon;

import React from 'react';
import { useDrop } from 'react-dnd';
import PropTypes from 'prop-types';
import { UiDroppable } from './Droppable.style';

export const Droppable = ({ type, children, dropHandle = () => {}, className }) => {

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: type,
    drop: (item, monitor) => {
      const from = item;
      dropHandle && dropHandle(item);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <UiDroppable ref={drop} className={className}>
      {children}
    </UiDroppable>
  );
};

Droppable.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.any,
  dropHandle: PropTypes.func
};
import React from 'react';
import PropTypes from 'prop-types';
import { UiFormItem, UiErrorMsg } from './FormItem.style';


/**
 * 表單 item
 */

export const FormItem = ({ subjectInfo, children }) => {
  const validateStatus = subjectInfo?.isPass ? 'success' : 'error';
  return (
    <UiFormItem validateStatus={validateStatus}>
      {children}
      {validateStatus === 'error' && <UiErrorMsg>{subjectInfo?.errorMsg}</UiErrorMsg> }
    </UiFormItem>
  );
};

FormItem.propTypes = {
  subjectInfo: PropTypes.object,
  children: PropTypes.node
};



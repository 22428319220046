import React from 'react';
import PropTypes from 'prop-types';
import { UiModal } from './Modal.style';


/**
 * 彈窗唷
 */

export const Modal = ({
  title = '',
  visible,
  confirmLoading,
  okText = '確定',
  cancelText = '取消',
  okButtonProps,
  onOk,
  onCancel,
  footer,
  children,
  width
}) => {
  return (
    <UiModal
      width={width}
      title={title}
      visible={visible}
      confirmLoading={confirmLoading}
      okText={okText}
      cancelText={cancelText}
      okButtonProps={okButtonProps}
      onOk={onOk}
      onCancel={onCancel}
      footer={footer}>
      {children}
    </UiModal>
  );
};

Modal.propTypes = {

};



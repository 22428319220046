import React from 'react';
import { Link } from 'react-router-dom';
import { BarsOutlined, FileAddOutlined } from '@ant-design/icons';
import { UiMenu } from './Menu.style';

export const Menu = () => {
  return (
    <UiMenu
      defaultSelectedKeys={['questionnaireList']}
      mode="inline"
    >
      <UiMenu.Item key="questionnaireList" icon={<BarsOutlined />}>
        <Link to='/'>表單列表</Link>
      </UiMenu.Item>
      <UiMenu.Item key="questionnaire" icon={<FileAddOutlined />}>
        <Link to='/questionnaire/create'>新增表單</Link>
      </UiMenu.Item>
    </UiMenu>
  );
};


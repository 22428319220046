import axios from 'axios';

export const exportFile = async (url, method, payload) => {
  axios({ url, method: method || 'get', params: payload, responseType: 'blob' }).then((res) => {
    const fileName = decodeURI(res.headers['content-disposition'].replace(/\w+;filename=(.*)/, '$1')).split('=')[1];
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  });
};

export const removeUndefinedFromObject = (obj) => {
  let result = {};
  Object.keys(obj).forEach(key => {
    if (typeof obj[key] !== 'undefined') {
      result[key] = obj[key];
    }
  });
  return result;
};
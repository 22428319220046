import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import { Select as AntSelect } from 'antd';
import { UiSelectIcon, UiSelect } from './Select.style';

export const Option = ({ children, value }) => {

  return (
    <AntSelect.Option value={value}>
      {children}
    </AntSelect.Option>
  );
};

export const Select = ({ required, children, onChange }) => {

  return (
    <UiSelect position="relative" width="100%">
      <AntSelect
        onSelect={onChange}
        showArrow={false}
        bordered={false}
        getPopupContainer={triggerNode => triggerNode.parentElement}
      >
        {children}
      </AntSelect>
      <UiSelectIcon>
        <Icon name="ArrowDown"/>
      </UiSelectIcon>
    </UiSelect>
  );
};

Select.propTypes = {
  onChange: PropTypes.func,
  required: PropTypes.bool
};
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Box, Modal, FormSelect, Button, FormInput } from '@oneform/ui-components';
import { Checkbox, Button as AntButton } from 'antd';
import { createValidator } from 'utils/validation';
import { UiQuestionnairePreview, UiSubjectLabel, UiSubjectDesc } from './QuestionnairePreview.style';

/**
 * 表單預覽
 */

const { Option } = FormSelect;

export const QuestionnairePreview = ({ data }) => {
  const history = useHistory();
  const [isVisible, setIsVisible] = useState(false);
  const [previewData, setPreviewData] = useState([]);

  const toggle = () => {
    setIsVisible(!isVisible);
  };

  useEffect(() => {
    if (!data) return;
    const subjectsData = data.subjects.map(item => {
      return {
        ...item,
        value: '',
        isPass: true,
        errorMsg: '',
      };
    });
    setPreviewData(subjectsData);
  }, [data]);

  const inputItemChangeHandler = (event, id) => {
    const value = event.target.value;
    const changeData = previewData.map(item => {
      if (item.subjectId === id || item.id === id) item.value = value;

      return item;
    });
    setPreviewData(changeData);
  };

  const selectItemChangeHandler = (value, id) => {
    const changeData = previewData.map(item => {
      if (item.subjectId === id || item.id === id) item.value = value;

      return item;
    });
    setPreviewData(changeData);
  };

  // 預覽送出
  const onFormSubmitHandler = () => {
    const nextData = previewData.map(item => {
      const validate = createValidator(item.rules);
      const [isPass, errorMsg] = validate(item.value);
      return {
        ...item,
        isPass: isPass,
        errorMsg,
      };
    });
    setPreviewData(nextData);
  };

  const creatFormItem = (subject, type) => {
    let itemHtml = '';
    switch (type) {
      case 'text':
        itemHtml = (
          <>
            <FormInput
              subjectInfo={subject}
              onChange={(event) => inputItemChangeHandler(event, subject.subjectId || subject.id)} />
            {subject?.label && <Box>標題：{subject?.label}</Box>}
            {subject?.description && <Box>備註：{subject?.description}</Box>}
            {subject?.rules.required && <Box>是否必填：{subject?.rules.required ? '是' : '否'}</Box>}
            {subject?.rules.minLength && <Box>字數長度：{subject?.rules.minLength}到{subject?.rules.maxLength}之間</Box>}
            {subject?.isHidden && <Box>隱藏欄位：{subject?.isHidden ? '是' : '否'}</Box>}
          </>
        );
        break;
      case 'singleChoice':
        itemHtml = (
          <>
            <FormSelect
              subjectInfo={subject}
              onChange={(value) => selectItemChangeHandler(value, subject.subjectId || subject.id)}>
              {
                subject.options.map((option, key) => {
                  return (
                    <Option key={option.value} value={option.value}>{option.value}</Option>
                  );
                })
              }
            </FormSelect>
            {subject?.label && <Box>標題：{subject?.label}</Box>}
            {subject?.description && <Box>備註：{subject?.description}</Box>}
            {subject?.rules.required && <Box>是否必填：{subject?.rules.required ? '是' : '否'}</Box>}
            {subject?.isHidden && <Box>隱藏欄位：{subject?.isHidden ? '是' : '否'}</Box>}
          </>
        );
        break;
      default:
        break;
    }

    return itemHtml;
  };

  return (
    <UiQuestionnairePreview>
      <div className="main">
        <h1>問卷標題 : {data?.title}</h1>
        {
          previewData &&
        previewData.map((subject, index) => {
          return (
            <Box my={2} key={index}>
              {creatFormItem(subject, subject.type)}
            </Box>
          );
        })
        }
        {data.copyright.title && <Box display="flex" justifyContent="center" my={4}>
          <Checkbox/>
          <span className="previewText">我同意</span>
          <span className="previewText inline" onClick={toggle}>{data.copyright.title}</span>
        </Box>}
        <Box display="flex" justifyContent="center">
          <AntButton type='primary' onClick={onFormSubmitHandler}>送出</AntButton>
        </Box>
      </div>
      <Modal
        title={data.copyright?.title}
        visible={isVisible}
        footer={[<Button type='primary' key="back" onClick={toggle}>關閉</Button>]}
        onCancel={toggle}
      >
        {data.copyright?.content}
      </Modal>
    </UiQuestionnairePreview>
  );
};

import React from 'react';
import { UiTable } from './Table.style';

export const Table = ({ columns, total, data, onPageChange }) => {
  const space = 70 + 24 + 64 + 32 + 55 + 32 + 40;
  const deductHeight = `${space}px`;
  return (
    <UiTable columns={columns} dataSource={data} pagination={{ total: total, onChange: onPageChange }} scroll={{ y: `calc(100vh - ${deductHeight} )` }}/>
  );
};

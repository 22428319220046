import React, { useEffect, useState } from 'react';
import { useSetState } from '../../utils/hooks/useSetState';
import { Input, Checkbox } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import Box from '../Box';
import SubjectBox from '../SubjectBox';
import PropTypes from 'prop-types';
import { UiSettingBlock, UiOptionBlock, UiOptionItem, UiOptionAction } from './OptionSubject.style';

export const OptionInner = ({
  id,
  label,
  value,
  onInputChangeHandler,
  onAddOptionHandler,
  onDeleteOptionHandler
}) => {
  const [inputValue, setInputValue] = useState(value);

  const onInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    onInputChangeHandler({
      id,
      value
    });
  };

  return (
    <UiOptionItem>
      <Box mx={2}>
        <Input onChange={onInputChange} value={inputValue} />
      </Box>
      <div className="optionActions">
        <UiOptionAction onClick={() => onAddOptionHandler(inputValue)}>
          <PlusOutlined />
        </UiOptionAction>
        <UiOptionAction onClick={() => onDeleteOptionHandler(id)}>
          <MinusOutlined />
        </UiOptionAction>
      </div>
    </UiOptionItem>
  );
};

export const OptionSubject = ({ index, onSave, onDelete, data }) => {
  const [{
    isRequired,
    isDesc,
    isHidden,
    isEdit,
    options,
    label,
    description
  }, setState] = useSetState({
    isRequired: data.rules.required || false,
    isDesc: data.description !== '' || false,
    isHidden: data.isHidden || false,
    isEdit: true,
    options: data.options || [],
    label: data.label || '',
    description: data.description || ''
  });
  const [prevData, setPrevData] = useState({});

  const checkOptions = [
    { label: '是否必填', name: 'isRequired', checked: isRequired },
    { label: '備註', name: 'isDesc', checked: isDesc },
    { label: '隱藏欄位', name: 'isHidden', checked: isHidden }
  ];

  const formatParams = () => {
    const newState = {
      isRequired,
      isDesc,
      isHidden,
      label,
      description,
      options
    };
    const result = {
      label: newState.label,
      description: newState.isDesc ? newState.description : '',
      placeholder: newState.placeholder,
      rules: {
        required: newState.isRequired,
      },
      options: newState.options.map(option => {
        return {
          value: option.value
        };
      }),
      isHidden: newState.isHidden,
    };

    return result;
  };

  const onChangeHandler = event => {
    const value = { [event.target.name]: event.target.checked };
    setState({
      ...value
    });
  };

  const onAddOptionHandler = (value) => {
    const id = uuidv4();
    const obj = {
      id,
      label: id,
      value: ''
    };
    const newOptionsData = options.length === 0 ? [] : options.map(a => ({ ...a }));
    newOptionsData.push(obj);

    setState({
      options: newOptionsData
    });
  };

  const onDeleteOptionHandler = (id) => {
    if (options.length === 1) return;
    const copyOptionsData = options.map(a => ({ ...a }));
    const newOptionsData = copyOptionsData.filter((item) => item.id !== id);

    setState({
      options: newOptionsData
    });
  };

  const onOptionInputChangeHandler = (option) => {
    const newOptionsData = options.map(a => ({ ...a }));
    newOptionsData.filter(item => item.id === option.id).map(item => item.value = option.value);
    setState({
      options: newOptionsData
    });
  };

  const onInputChangeHandler = (event, key) => {
    const value = event.target?.value;
    setState({
      [key]: value
    });
  };

  const onEdit = () => {
    setPrevData({
      isRequired,
      isDesc,
      isHidden,
      label,
      description,
      options
    });
    setState({
      isEdit: !isEdit
    });
  };

  const onSaveHandler = () => {
    setState({
      isEdit: false
    });
    const params = formatParams();
    onSave(params);

  };

  const onCancel = () => {
    setState({
      ...prevData,
      isEdit: false,
    });
  };

  useEffect(() => {
    if (options.length > 0) {
      const newOptionsData = options.map(item => {
        const id = uuidv4();
        const obj = {
          id,
          label: id,
          value: item.value
        };
        return obj;
      });

      setState({
        options: newOptionsData
      });
    } else {
      onAddOptionHandler();
    }
  }, []);

  return (
    <SubjectBox
      isRequired={isRequired}
      index={index}
      onDelete={onDelete}
      onCancel={onCancel}
      onEdit={onEdit}
      onSave={onSaveHandler}
      isEdit={isEdit}>
      <Box my={3}>
        <Input placeholder='標題' onChange={e => onInputChangeHandler(e, 'label')} value={label} />
      </Box>
      {isEdit &&
      <Box>
        {isDesc &&
        <Box my={3}>
          <Input placeholder='備註說明' onChange={e => onInputChangeHandler(e, 'description')} value={description} />
        </Box>}
        <UiSettingBlock>
          {checkOptions.map(option => <Checkbox key={option.name} name={option.name} checked={option.checked} onChange={onChangeHandler}>{option.label}</Checkbox>)}
        </UiSettingBlock>
      </Box>}
      <Box my={3}>
        <UiOptionBlock>
          {options.length > 0 && options.map(item =>
            <li key={item.id}>
              <OptionInner
                id={item.id}
                label={item.label}
                value={item.value}
                onInputChangeHandler={onOptionInputChangeHandler}
                onAddOptionHandler={onAddOptionHandler}
                onDeleteOptionHandler={onDeleteOptionHandler} />
            </li> )}
        </UiOptionBlock>
      </Box>
    </SubjectBox>
  );
};

OptionSubject.propTypes = {
  index: PropTypes.number
};

import React from 'react';
import { Avatar, Dropdown, Menu, Button, Space } from 'antd';
import { UserOutlined, DownOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { UiUserAvatar } from './UserAvatar.style';

/**
 * 頭像
 */

export const UserAvatar = ({ name = '望先生', onLogout }) => {
  const menu = (
    <Menu>
      <Menu.Item onClick={onLogout}>
        登出
      </Menu.Item>
    </Menu>
  );
  return (
    <UiUserAvatar>
      <Space>
        <Avatar icon={<UserOutlined />} />
        <Dropdown overlay={menu} trigger={['click']}>
          <Button>{name}<DownOutlined /></Button>
        </Dropdown>
      </Space>
    </UiUserAvatar>);
};

UserAvatar.propTypes = {
  name: PropTypes.string,
  onLogout: PropTypes.func
};



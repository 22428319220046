import React, { useEffect, useState } from 'react';
import { useMachine } from '@xstate/react';
import { fetchMachine, FetchEventType } from 'machines/fetchMachine';
import { getQuestionnaireList } from 'api/questionnaire';
import { Table, Box } from '@oneform/ui-components';
import { Space, Input } from 'antd';
import { Link } from 'react-router-dom';

const { Search } = Input;

const columns = [
  {
    title: '表單名稱',
    dataIndex: 'title',
    key: 'title'
  },
  {
    title: '表單資訊',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: '填表數量',
    dataIndex: 'fillAmount',
    key: 'fillAmount',
  },
  {
    title: '操作',
    key: 'action',
    render: (text, questionnaire) => {
      return (
        <Space size="middle">
          <Link to={`/questionnaire/edit/${questionnaire.id}`}>編輯</Link>
          <Link to={`/record/${questionnaire.id}`}>匯出</Link>
        </Space>
      );
    },
  },
];

export const QuestionnaireList = () => {
  const [questionnaireList, setQuestionnaireList] = useState();
  const [listData, send] = useMachine(fetchMachine, {
    services: {
      fetchData: async (_context, event) => {
        const res = await getQuestionnaireList(event.payload);
        return res.data;
      },
    },
  });
  const { questionnaires: questionnaireListData, total } = listData.context.result || [];

  const onPageChangeHandler = (page, pageSize) => {
    const limit = pageSize;
    const skip = (page - 1) * limit;
    send(FetchEventType.Fetch, {
      payload: {
        skip,
        limit
      }
    });
  };

  useEffect(() => {
    if (!questionnaireListData) return;
    const nextQuestionnaireList = questionnaireListData.map(a => a).sort((a, b) => b.createdTime - a.createdTime);
    setQuestionnaireList(nextQuestionnaireList);
  }, [questionnaireListData]);

  useEffect(() => {
    send(FetchEventType.Fetch, {
      payload: {
        skip: 0,
        limit: 10
      }
    });
  }, []);

  return (
    <>
      <Box mb={2}>
        <Search style={{ width: 200 }} enterButton />
      </Box>
      {questionnaireListData && <Table columns={columns} data={questionnaireList} total={total} onPageChange={onPageChangeHandler} />}
    </>
  );
};
import React from 'react';
import Box from '../Box';
import SVG from './svg';


export const Icon = ({ name }) => {
  return (
    <Box width="100%" height="100%">
      {name && SVG[name]()}
    </Box>
  );
};
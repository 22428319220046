import { createMachine, assign } from 'xstate';
import openNotificationWithIcon from 'utils/hooks/useNotification';

export const FetchEventType = {
  Fetch: 'FETCH',
  Create: 'CREATE',
  Update: 'UPDATE',
  Delete: 'DELETE',
};

export const FetchStates = {
  Pending: 'Pending',
  Loading: 'Loading',
  Creating: 'Creating',
  Updating: 'Updating',
  Deleting: 'Deleting',
  Resolved: 'Resolved',
  Rejected: 'Rejected',
};

export const systemCode = {
  '0201': 'Insert Failed',
  '0211': '更新失敗！',
  '0221': '下載失敗！',
  '0222': '解析失敗！',
  '0223': '檢查失敗！',
  '0224': '匯入失敗！',
  '0400': '資料不存在！',
  '0401': '資料已經存在！',
  '0491': 'Code 已經存在',
  '0492': '名稱已重複！',
  '0493': 'Parent Code 不存在！',
  '0494': 'Code 不存在！',
  '0495': 'UID 不存在！',
  '0496': 'Type or Attribute 不存在！',
  '0497': 'Code 不存在！',
  '0498': 'Data 不允許',
  '9999': '例外總是來得這麼突然',
};

export const fetchMachine = createMachine(
  {
    id: 'fetchMachine',
    initial: FetchStates.Pending,
    context: {
      result: null,
      error: null,
    },
    states: {
      [FetchStates.Pending]: {
        on: {
          [FetchEventType.Fetch]: FetchStates.Loading,
        },
      },
      [FetchStates.Loading]: {
        invoke: {
          id: 'fetchLoading',
          src: 'fetchData',
          onDone: {
            target: FetchStates.Resolved,
            actions: assign({
              result: (_context, event) => {
                return event.data;
              },
            }),
          },
          onError: {
            target: FetchStates.Rejected,
            actions: assign({
              error: (_context, event) => {
                return event.data;
              },
            }),
          },
        },
      },
      [FetchStates.Creating]: {
        invoke: {
          id: 'creating',
          src: 'createData',
          onDone: {
            target: FetchStates.Loading,
            actions: (_context, event) => {
              if (event.data.isSuccess) {
                openNotificationWithIcon('success', '新增成功！');
              } else {
                openNotificationWithIcon('error', event.data.message || systemCode[event.data.systemCode]);
              }
            },
          },
          onError: {
            target: FetchStates.Rejected,
            actions: assign({
              error: (_context, event) => {
                return event.data.error;
              },
            }),
          },
        },
      },
      [FetchStates.Updating]: {
        invoke: {
          id: 'updating',
          src: 'updateData',
          onDone: {
            target: FetchStates.Loading,
            actions: (_context, event) => {
              if (event.data.isSuccess) {
                openNotificationWithIcon('success', '更新成功！');
              } else {
                openNotificationWithIcon('error', event.data.message || systemCode[event.data.systemCode]);
              }
            },
          },
          onError: {
            target: FetchStates.Rejected,
            actions: assign({
              error: (_context, event) => {
                return event.data.error;
              },
            }),
          },
        },
      },
      [FetchStates.Deleting]: {
        invoke: {
          id: 'deleting',
          src: 'deleteData',
          onDone: {
            target: FetchStates.Loading,
            actions: () => {
              openNotificationWithIcon('success', '刪除成功！');
            },
          },
          onError: {
            target: FetchStates.Rejected,
            actions: assign({
              error: (_context, event) => {
                return event.data.error;
              },
            }),
          },
        },
      },
      [FetchStates.Resolved]: {
        on: {
          [FetchEventType.Fetch]: FetchStates.Loading,
          [FetchEventType.Create]: FetchStates.Creating,
          [FetchEventType.Update]: FetchStates.Updating,
          [FetchEventType.Delete]: FetchStates.Deleting,
        },
      },
      [FetchStates.Rejected]: {
        entry: ['showErrorMsg'],
        on: {
          [FetchEventType.Fetch]: FetchStates.Loading,
          [FetchEventType.Create]: FetchStates.Creating,
          [FetchEventType.Update]: FetchStates.Updating,
          [FetchEventType.Delete]: FetchStates.Deleting,
        },
      },
    },
  },
  {
    actions: {
      showErrorMsg: (context) => {
        openNotificationWithIcon('error', context.error.message || '未知錯誤');
      },
    },
  },
);

export default fetchMachine;

import styled from 'styled-components';
import { get } from '../../utils/themeGet';

export const UiQuestionnairePreview = styled.div`
	display:flex;
	align-items:center;
	justify-content:center;
	width: 100%;

	.main {
		background-color: #fff;
		display: block;
		color:#242c3f;
		width: 416px;
	}
	.previewText {
		margin-left: 4px;
		display:inline-block;

		&.inline {
			text-decoration:underline;
			cursor:pointer;
			color:#4c99a5;
		}
	}
`;

export const UiButtonBox = styled.div`
	display:flex;
	justify-content:flex-end;

	> .button {
		padding: 9px 16px;
		border-radius:8px;
		background-color: #000;
		color:#fff;
		width:max-content;
		cursor:pointer;
		transition:0.35s;

		&:hover {
			opacity:0.65;
		}
	}
`;

export const UiSubjectLabel = styled.div`
	font-size: 14px;
	color:#242c3f;
`;

export const UiSubjectDesc = styled.div`
	font-size: 14px;	 
	color: ${get('colors.scale.gray.$gray3')};
`;

import styled from 'styled-components';
import { Layout } from 'antd';
import { get } from '../../utils/themeGet';

const { Header, Content, Footer, Sider } = Layout;

export const UiSideLayout = styled(Layout)`
  height: 100vh;
`;

export const UiSiteLayout = styled(Layout)`
  height: 100vh;
`;

export const UiHeader = styled(Header)`
`;

export const UiContent = styled(Content)`
  padding:${get('space.4')};
`;

export const UiFooter = styled(Footer)`
  text-align: center;
`;

export const UiSider = styled(Sider)`
`;

import React from 'react';
import PropTypes from 'prop-types';
import FormItem from '../FormItem';
import Icon from '../Icon';
import { Select as AntSelect } from 'antd';
import { UiSelectIcon, UiFormSelect } from './FormSelect.style';


/**
 * 表單 select
 */

export const Option = ({ children, value }) => {

  return (
    <AntSelect.Option value={value}>
      {children}
    </AntSelect.Option>
  );
};

export const FormSelect = ({ subjectInfo, onChange = () => {}, children }) => {
  return (
    <FormItem subjectInfo={subjectInfo}>
      <UiFormSelect position="relative" width="100%">
        <AntSelect
          onSelect={onChange}
          showArrow={false}
          bordered={false}
          value={subjectInfo?.value}
          getPopupContainer={triggerNode => triggerNode.parentElement}
          placeholder={`${subjectInfo?.placeholder || '請輸入'}${(subjectInfo?.rules?.required === true) ? '*' : ''}`}
        >
          {children}
        </AntSelect>
        <UiSelectIcon>
          <Icon name="ArrowDown"/>
        </UiSelectIcon>
      </UiFormSelect>
    </FormItem>
  );
};

FormSelect.propTypes = {
  subjectInfo: PropTypes.object,
  onChange: PropTypes.func,
  children: PropTypes.node
};



import React from 'react';
import { Editor } from 'containers';
import { UiEditQuestionnairePage } from './EditQuestionnairePage.style';


/**
 * 編輯頁面
 */

export const EditQuestionnairePage = () => {
  return (<Editor type='edit' />);
};


import React, { useState, useEffect } from 'react';
import { Input } from 'antd';
import { useHistory } from 'react-router-dom';
import { UserOutlined, EyeInvisibleOutlined, EyeTwoTone, ExclamationCircleOutlined } from '@ant-design/icons';
import { AuthenticationMachineState, AuthenticationMachineEvent } from 'machines/authMachine';
import { useAuthState, useAuthDispatch } from 'providers/AuthProvider';
import { sendData as loginApi, getProfile as getProfileApi } from 'services/oneClub';
import { createLoginValidator, required } from 'utils/validation';
import { UiLogin } from './LoginPage.style';

export const LoginPage = () => {
  const authDispatch = useAuthDispatch();
  const authState = useAuthState();
  const history = useHistory();
  const [state, setState] = useState({
    password: {
      value: '',
      message: '',
    },
    account: {
      value: '',
      message: '',
    },
    errorMessage: '',
  });
  const onChange = (event, key) => {
    if (!event) return;
    const value = event.target.value;
    setState({
      ...state,
      [key]: {
        value,
        message: ''
      },
      errorMessage: ''
    });
  };

  const submit = async () => {
    const values = {
      account: state.account.value,
      password: state.account.value,
    };
    const rules = {
      account: [
        {
          message: '帳號欄位不得為空',
          validate: required,
        },
      ],
      password: [
        {
          message: '密碼欄位不得為空',
          validate: required,
        },
      ],
    };
    const [isPass, errorGroup] = createLoginValidator(values, rules);
    setState({ ...state, errorGroup });
    if (isPass) return;
    const result = await loginApi(state.account.value, state.password.value);
    if (result.code !== 'SUCCESS') {
      setState({
        ...state,
        account: { value: '', message: '' },
        password: { value: '', message: '' },
        errorMessage: result.message,
      });
      return;
    }
    const jwt = JSON.parse(result.jwt).jwt;
    const res = await getProfileApi({ jwt });
    if (res.identity === '編輯') {
      authDispatch(AuthenticationMachineEvent.LOG_IN, {
        userDetails: {
          ...res
        }
      });
    } else {
      setState({
        ...state,
        account: { value: '', message: '' },
        password: { value: '', message: '' },
        errorMessage: '此帳號非編輯身份',
      });
    }

  };

  useEffect(() => {
    if (authState.value === AuthenticationMachineState.loggedIn) {
      history.push('/');
    }
  }, [authState.value]);

  return (
    <UiLogin>
      <div className="mainContainer">
        <h1 className="title">One Form</h1>
        <div className="title prompt">登入以繼續</div>
        {state.errorMessage ?
          <div className="error">
            <ExclamationCircleOutlined />
            {state.errorMessage}
          </div> : <div className="noError"></div>
        }
        <div className="inputBox">
          <span>帳號</span>
          <Input
            placeholder="請輸入帳號"
            suffix={<UserOutlined className="site-form-item-icon" />}
            onChange={(e) => onChange(e, 'account')}
            value={state.account.value}
          />
        </div>
        <div className="inputBox">
          <span>密碼</span>
          <Input.Password
            placeholder="請輸入密碼"
            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            onChange={(e) => onChange(e, 'password')}
            value={state.password.value}
          />
        </div>
        <div className="submit" onClick={submit}>登入</div>
      </div>
    </UiLogin>
  );
};

import React from 'react';
import { QuestionnairePreview } from 'containers';
import { UiQuestionnairePreviewPage } from './QuestionnairePreviewPage.style';


/**
 * 表單預覽
 */

export const QuestionnairePreviewPage = () => {
  return (
    <UiQuestionnairePreviewPage>
      <QuestionnairePreview/>
    </UiQuestionnairePreviewPage>
  );
};

import styled from 'styled-components';
import { Button as AntButton } from 'antd';
import { get } from '../../utils/themeGet';

export const UiButton = styled(AntButton)`
  background-color: #121232;
  border: none;
  &:hover{
    background-color: ${get('colors.scale.gray.$gray7')};
  }
`;
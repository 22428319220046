import React from 'react';
import PropTypes from 'prop-types';
import { Input as AntInput } from 'antd';
import { UiInput } from './Input.style';


/**
 * 文字輸入匡
 */

export const Input = ({ label, placeholder, onChange = () => {}, }) => {
  return (
    <UiInput>
      <div className="label">{label}</div>
      <AntInput onChange={onChange} placeholder={placeholder} />
    </UiInput>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string
};



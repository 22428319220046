import React from 'react';
import { RecordList } from 'containers';
import { UiRecordPage } from './RecordPage.style';

/**
 * 填答頁面
 */

export const RecordPage = () => {
  return (
    <UiRecordPage>
      <RecordList />
    </UiRecordPage>);
};



import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { BrowserRouter } from 'react-router-dom';
import AuthProvider from 'providers/AuthProvider';
import 'antd/dist/antd.css';
import './index.css';
import { ThemeProvider } from '@oneform/ui-components';


const root = document.getElementById('root');
const Main = () => (
  <BrowserRouter>
    <AuthProvider>
      <ThemeProvider>
        <DndProvider backend={HTML5Backend}>
          <App />
        </DndProvider>
      </ThemeProvider>
    </AuthProvider>
  </BrowserRouter>
);

ReactDOM.render(Main(), root);

reportWebVitals();

import styled from 'styled-components';

export const UiEditor = styled.div`
  width:100%;
  display:flex;
  height:100%;
  overflow:hidden;
`;

export const UiEditorContent = styled.section`
  width: 65%;
  flex:1;
  height: 100%;
  overflow-x:hidden;
  background-color: #fff;

  & > div {
    flex-direction:column;
    height: max-content;
    min-height:100%;
    
    & > * + * {
      margin-top: 10px;
    }
  }

  .editorContent_tool {
    width: 100%;
  }
`;

export const UiToolsContent = styled.section`
  width: 35%;
  min-width:350px;
  padding: 0 0 0 12px;

  .toolsContent_draggable {
    height: 35%;
    background-color: #fff;
    padding: 12px;
  }

  .finalSettingContent {
    margin-top: 12px;
    padding: 12px 0 12px 12px;
    height: calc(65% - 12px);
    background-color: #fff;
    display: flex;
    flex-direction: column;
  }
  .finalSetting{
    padding-right: 12px;
    overflow-x:hidden;
    height: 100%;
  }
  .finalSettingAction{
    padding: 12px 12px 0 0;
    height: 44px;
  }
  .toolsContent_tool {
    width: calc(50% - 10px);
    margin: 4px;
  }
`;

export const UiEditorTool = styled.div`
  border:1px dashed #fff;
  background-color: #000;
  color:#fff;
  cursor: grab;
  padding: 4px 10px;
  text-align: center;
`;
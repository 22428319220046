import React, { useState } from 'react';
import { Editor } from 'containers';
import { UiCreateQuestionnairePage } from './CreateQuestionnaire.style';


export const CreateQuestionnairePage = () => {
  return (
    <Editor type='create' />
  );
};

import styled from 'styled-components';
import { Input } from 'antd';

export const UiFormInput = styled(Input)`
	padding: 12px;
	color:#242c3f;
	border-radius:8px;

	&:focus{
		border-color: #121232;
		box-shadow: none;
	}
`;

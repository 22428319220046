import styled from 'styled-components';

export const UiFilletButton = styled.div`
	display: block;
	width:100%;
	color:#fff;
	background-color: #EC7963;
	font-weight: 500;
	font-size: 20px;
	padding: 16px;
	border-radius:50px;
	text-align:center;
	cursor:pointer;
	transition:0.35s;

	&:hover {
		background-color: #ee8773;
	}
`;
import styled from 'styled-components';

export const UiLogin = styled.div`
  position: fixed;
	top: 0;
	display: flex;
	align-items: center;
  justify-content: center;
	width: 100%;
	height: 100vh;
	background-color: rgb(0, 0, 0);
  
  & > .mainContainer {
    width: 100%;
    max-width:360px;
    background-color: #000;
    border-radius:5px;
    display:flex;
    flex-direction:column;
    align-items:center;
    border-radius:5px;
    border:2px solid #fff;
    justify-content:space-between;
    padding:10px 20px 30px;

    > .title {
      color:#fff;
      font-size: 22px;
      font-weight:bold;
      text-align:center;
      width:max-content;

      &.prompt {
        font-size: 28px;
      }
    }

    > .error {
      background-color: #f00;
      width: 100%;
      max-width:250px;
      color:#fff;
      padding: 12px 8px;
      margin: 12px 0;
      border-radius:5px;
      font-weight:bold;
      font-size: 14px;

      > .anticon {
        margin-right: 8px;
      }
    }

    > .noError {
      margin: 12px 0;
      height: 46px;
      width: 100%;
    }

    > .inputBox {
        max-width:250px;
        width: 100%;

      > span {
        color:#fff;
      }

      > .ant-input-affix-wrapper {
        background-color: rgba(125,125,125,0.6);
        border:none;
        margin: 8px 0;
        padding: 8px 12px;
        border-radius:5px;
        > .ant-input {
          color:#fff;
          background-color: transparent;
        }
      }
    }

    > .submit {
      background-color: #fff;
      margin: 16px 0 0;
      padding: 14px 0 16px;
      font-weight:bold;
      width: 250px;
      border-radius:40px;
      color:#616467;
      text-align:center;
      cursor: pointer;
      transition:0.1s;
      font-size: 14px;
      line-height: 1;
      border-radius: 500px;
      border-width: 0;
      letter-spacing: 2px;
      min-width: 160px;
      text-transform: uppercase;
      white-space: normal;

      &:hover {
        background-color: rgb(146 ,223 ,132);
        color:#fff;
        transform:scale(1.2);
      }
    }
  }
`;
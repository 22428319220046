import styled from 'styled-components';
import { Modal as AntModal } from 'antd';

export const UiModal = styled(AntModal)`
	.ant-modal-body {
		& > div + div {
			margin-top: 10px;
		}
	}

	.ant-modal-title {
		display: flex;
		align-items: center;
		font-size: 32px;
		color: #242c3f;

		& > i {
			margin-right: 5px;
			color: #242c3f;
		}
	}

	.ant-dropdown-menu-item {
		& > div {
			display: flex;
			align-items: center;
		}

		i {
			margin-right: 5px;
			font-size: 14px;
		}
	}
`;
import React from 'react';
import { MenuOutlined, DeleteFilled, EditFilled } from '@ant-design/icons';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import Box from '../Box';
import { UiSubjectBox, UiSideBlock, UiHeadBlock, UiMainBlock, UiToolBlock } from './SubjectBox.style';

export const SubjectBox = ({
  isRequired,
  onDelete = () => {},
  onCancel = () => {},
  onEdit = () => {},
  onSave = () => {},
  children,
  isEdit,
  index
}) => {
  return (
    <UiSubjectBox>
      <UiHeadBlock>
        <UiSideBlock>
          <div className="sideLabel">
            <span>{index + 1}</span>
            {isRequired && <span className="requiredIcon">*</span>}
          </div>
        </UiSideBlock>
        <UiToolBlock>
          <div className="toolItem" onClick={onEdit}>
            <EditFilled />
          </div>
          <div className="toolItem" onClick={onDelete}>
            <DeleteFilled />
          </div>
          <div className="toolItem move">
            <MenuOutlined />
          </div>
        </UiToolBlock>
      </UiHeadBlock>
      <UiMainBlock>
        <div className="content">
          {children}
        </div>
        {isEdit && <div className="action">
          <Button onClick={onCancel}>取消</Button>
          <Box ml={2}>
            <Button type="primary" onClick={onSave}>完成</Button>
          </Box>
        </div>}
      </UiMainBlock>
    </UiSubjectBox>
  );
};

SubjectBox.propTypes = {
  onChange: PropTypes.func,
  index: PropTypes.number,
  children: PropTypes.any,
};
import styled from 'styled-components';
import { get } from '../../utils/themeGet';

export const UiSubjectBox = styled.div`
  padding: 16px;
  width: 100%;
`;
export const UiSideBlock = styled.div`
  flex-basis: 50px;

  .sideLabel{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    color: ${get('colors.label.primary.text')};
    border-radius: ${get('radii.1')};
    background-color: ${get('colors.label.primary.bg')};
    
    .requiredIcon{
      position: absolute;
      left: 4px;
      top: 2px;
      font-size: 10px;
    }
  }
`;

export const UiHeadBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;


export const UiMainBlock = styled.div`
  width: 100%;

  >.content{
    margin: 8px 0;
  }

  >.action{
    display: flex;
    justify-content: flex-end;
  }
`;

export const UiSettingBlock = styled.div`
`;

export const UiToolBlock = styled.div`
  display:flex;
  justify-content: flex-end;

  .toolItem {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    cursor: pointer;
    color: ${get('colors.scale.gray.$gray3')};

    &:hover{
      color: ${get('colors.scale.gray.$gray8')};
    }

    &.move {
      cursor: move;
    }
  }
`;


import {
  LoginPage,
  QuestionnaireListPage,
  CreateQuestionnairePage,
  QuestionnairePreviewPage,
  EditQuestionnairePage,
  RecordPage
} from 'views';

import { MainLayout } from 'layouts';

const routes = [
  {
    path: '/login',
    component: LoginPage,
    exact: true,
  },
  {
    path: '/preview',
    component: QuestionnairePreviewPage,
    exact: true,
  },
  {
    path: '/',
    component: MainLayout,
    exact: true,
    routes: [
      {
        path: '/',
        component: QuestionnaireListPage,
        name: 'questionnaireList',
        exact: true,
      }
    ],
  },
  {
    path: '/questionnaire',
    component: MainLayout,
    routes: [
      {
        path: '/questionnaire/create',
        component: CreateQuestionnairePage,
        name: 'createQuestionnaire',
        exact: true,
      },
      {
        path: '/questionnaire/edit/:id',
        component: EditQuestionnairePage,
        name: 'editQuestionnaire',
        exact: true,
      },
    ],
  },
  {
    path: '/record',
    component: MainLayout,
    routes: [
      {
        path: '/record',
        component: RecordPage,
        name: 'record',
        exact: true,
      },
      {
        path: '/record/:id',
        component: RecordPage,
        name: 'record',
        exact: true,
      }
    ],
  },
];
export default routes;
